// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type YoutubeSvgrepoComsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function YoutubeSvgrepoComsvgIcon(props: YoutubeSvgrepoComsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"currentColor"}
      version={"1.1"}
      viewBox={"-143 145 512 512"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path d={"M78.9 450.3l83.8-49.2-83.8-49.2z"}></path>

      <path
        d={
          "M-143 145v512h512V145h-512zm384 301.8c0 44.1-44.1 44.1-44.1 44.1H29.1c-44.1 0-44.1-44.1-44.1-44.1v-91.5c0-44.1 44.1-44.1 44.1-44.1h167.8c44.1 0 44.1 44.1 44.1 44.1v91.5z"
        }
      ></path>
    </svg>
  );
}

export default YoutubeSvgrepoComsvgIcon;
/* prettier-ignore-end */
